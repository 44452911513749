import React, { useState, useEffect } from 'react';
import { FaCircle, FaInfoCircle } from 'react-icons/fa';

const ConexionIndicador = ({ estaConectado }) => {
  const [ping, setPing] = useState(null);
  const [ultimaActualizacion, setUltimaActualizacion] = useState(null);
  const [mostrarDetalles, setMostrarDetalles] = useState(false);

  useEffect(() => {
    const medirPing = () => {
      const inicio = Date.now();
      fetch('/ping') // Asegúrate de tener un endpoint /ping en tu servidor
        .then(() => {
          const fin = Date.now();
          setPing(fin - inicio);
          setUltimaActualizacion(new Date().toLocaleTimeString());
        })
        .catch(() => {
          setPing(null);
          setUltimaActualizacion(null);
        });
    };

    if (estaConectado) {
      medirPing();
      const intervalo = setInterval(medirPing, 30000); // Medir cada 30 segundos
      return () => clearInterval(intervalo);
    }
  }, [estaConectado]);

  return (
    <div className="conexion-indicador">
      <FaCircle color={estaConectado ? "#4CAF50" : "#F44336"} />
      <span>{estaConectado ? "Online" : "Offline"}</span>
      {estaConectado && (
        <>
          <span className="ping">{ping ? `${ping}ms` : '...'}</span>
          <FaInfoCircle 
            className="info-icon" 
            onMouseEnter={() => setMostrarDetalles(true)}
            onMouseLeave={() => setMostrarDetalles(false)}
          />
          {mostrarDetalles && (
            <div className="conexion-detalles">
              <p>Última actualización: {ultimaActualizacion}</p>
              <p>Estado: {estaConectado ? 'Conectado' : 'Desconectado'}</p>
              <p>Ping: {ping ? `${ping}ms` : 'N/A'}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ConexionIndicador;