import React, { useState } from 'react';
import { FaSearch, FaSort, FaPlus, FaEye, FaEdit, FaTrash, FaTag, FaDollarSign, FaChartLine, FaFilter, FaThLarge, FaList, FaChevronLeft, FaChevronRight, FaPercent } from 'react-icons/fa';

export const ListaProductos = ({ 
  busqueda, 
  setBusqueda, 
  filtroCategoria, 
  setFiltroCategoria, 
  categorias, 
  setVistaActual, 
  ordenProductos, 
  ordenarProductos, 
  productosFiltradosYOrdenados, 
  mostrarDetalles, 
  mostrarFormulario,
  editarProducto,
  eliminarProducto
}) => {
  const [vistaGrilla, setVistaGrilla] = useState(true);
  const [paginaActual, setPaginaActual] = useState(1);
  const [filtrosAvanzados, setFiltrosAvanzados] = useState({
    precioMin: '',
    precioMax: '',
    rentabilidadMin: '',
    rentabilidadMax: ''
  });
  const [mostrarFiltrosAvanzados, setMostrarFiltrosAvanzados] = useState(false);
  const productosPorPagina = 20;

  const indiceUltimoProducto = paginaActual * productosPorPagina;
  const indicePrimerProducto = indiceUltimoProducto - productosPorPagina;
  const productosActuales = productosFiltradosYOrdenados.slice(indicePrimerProducto, indiceUltimoProducto);

  const cambiarPagina = (numeroPagina) => {
    if (numeroPagina >= 1 && numeroPagina <= Math.ceil(productosFiltradosYOrdenados.length / productosPorPagina)) {
      setPaginaActual(numeroPagina);
      // Desplazamiento suave hacia arriba
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const opcionesDeCampos = [
    { valor: 'nombre', texto: 'Nombre' },
    { valor: 'categoria', texto: 'Categoría' },
    { valor: 'precioVenta', texto: 'Precio' },
    { valor: 'rentabilidad', texto: 'Rentabilidad' }
  ];

  const handleFiltroAvanzadoChange = (e) => {
    const { name, value } = e.target;
    setFiltrosAvanzados(prev => ({ ...prev, [name]: value }));
  };

  const aplicarFiltrosAvanzados = () => {
    // Aquí implementarías la lógica para aplicar los filtros avanzados
    // Por ahora, solo cerraremos el panel de filtros avanzados
    setMostrarFiltrosAvanzados(false);
  };

  return (
    <div className="productos-lista">
      <div className="filtros-container">
        <div className="busqueda-container">
          <FaSearch className="icono-busqueda" />
          <input
            type="text"
            placeholder="Buscar producto..."
            value={busqueda}
            onChange={(e) => setBusqueda(e.target.value)}
          />
        </div>
        <div className="filtro-categoria">
          <FaFilter className="icono-filtro" />
          <select
            value={filtroCategoria}
            onChange={(e) => setFiltroCategoria(e.target.value)}
          >
            <option value="">Todas las categorías</option>
            {categorias.map(cat => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
        </div>
        <button onClick={() => setMostrarFiltrosAvanzados(!mostrarFiltrosAvanzados)} className="btn btn-secondary">
          Filtros Avanzados
        </button>
        <div className="ordenar-container">
          <select
            value={ordenProductos.campo}
            onChange={(e) => ordenarProductos(e.target.value)}
          >
            {opcionesDeCampos.map(opcion => (
              <option key={opcion.valor} value={opcion.valor}>{opcion.texto}</option>
            ))}
          </select>
          <button onClick={() => ordenarProductos(ordenProductos.campo)} className="btn btn-ordenar">
            <FaSort /> {ordenProductos.direccion === 'asc' ? 'Asc' : 'Desc'}
          </button>
        </div>
        <div className="vista-toggle">
          <button onClick={() => setVistaGrilla(true)} className={`btn ${vistaGrilla ? 'active' : ''}`}>
            <FaThLarge />
          </button>
          <button onClick={() => setVistaGrilla(false)} className={`btn ${!vistaGrilla ? 'active' : ''}`}>
            <FaList />
          </button>
        </div>
      </div>
      {mostrarFiltrosAvanzados && (
        <div className="filtros-avanzados">
          <div className="filtro-grupo">
            <label>Precio Mínimo:</label>
            <input
              type="number"
              name="precioMin"
              value={filtrosAvanzados.precioMin}
              onChange={handleFiltroAvanzadoChange}
            />
          </div>
          <div className="filtro-grupo">
            <label>Precio Máximo:</label>
            <input
              type="number"
              name="precioMax"
              value={filtrosAvanzados.precioMax}
              onChange={handleFiltroAvanzadoChange}
            />
          </div>
          <div className="filtro-grupo">
            <label>Rentabilidad Mínima (%):</label>
            <input
              type="number"
              name="rentabilidadMin"
              value={filtrosAvanzados.rentabilidadMin}
              onChange={handleFiltroAvanzadoChange}
            />
          </div>
          <div className="filtro-grupo">
            <label>Rentabilidad Máxima (%):</label>
            <input
              type="number"
              name="rentabilidadMax"
              value={filtrosAvanzados.rentabilidadMax}
              onChange={handleFiltroAvanzadoChange}
            />
          </div>
          <button onClick={aplicarFiltrosAvanzados} className="btn btn-primary">
            Aplicar Filtros
          </button>
        </div>
      )}
      <div className={vistaGrilla ? "productos-grid" : "productos-lista"}>
        {productosActuales.map((producto) => (
          <div key={producto.id} className={vistaGrilla ? "producto-card" : "producto-item"}>
            <div className="producto-info">
              <h3>{producto.nombre}</h3>
              <p className="categoria"><FaTag /> {producto.categoria}</p>
              <div className="producto-stats">
                <p className="precio"><FaDollarSign /> {parseFloat(producto.precioVenta).toFixed(2)}</p>
                <p className="rentabilidad"><FaChartLine /> ROI: {producto.roi}%</p>
                <p className="margen"><FaPercent /> Margen: {producto.margenBruto}%</p>
              </div>
            </div>
            <div className="producto-acciones">
              <button onClick={() => mostrarDetalles(producto)} className="btn btn-detalle" aria-label="Ver detalles">
                <FaEye />
              </button>
              <button onClick={() => editarProducto(producto)} className="btn btn-editar" aria-label="Editar producto">
                <FaEdit />
              </button>
              <button onClick={() => eliminarProducto(producto.id)} className="btn btn-eliminar" aria-label="Eliminar producto">
                <FaTrash />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="paginacion">
        <button 
          onClick={() => cambiarPagina(paginaActual - 1)} 
          disabled={paginaActual === 1}
          className="btn-paginacion"
        >
          <FaChevronLeft />
        </button>
        <span>{paginaActual} de {Math.ceil(productosFiltradosYOrdenados.length / productosPorPagina)}</span>
        <button 
          onClick={() => cambiarPagina(paginaActual + 1)} 
          disabled={paginaActual === Math.ceil(productosFiltradosYOrdenados.length / productosPorPagina)}
          className="btn-paginacion"
        >
          <FaChevronRight />
        </button>
      </div>
      <button className="btn-agregar-producto" onClick={mostrarFormulario} aria-label="Agregar Producto">
        <FaPlus />
      </button>
    </div>
  );
};