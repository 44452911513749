import React, { useState, useCallback, useMemo } from 'react';
import { useTarifas } from '../hooks/useTarifas';
import { FaPlus, FaEdit, FaTrash, FaSave, FaTimes, FaInfoCircle, FaPercent } from 'react-icons/fa';
import '../styles/AjusteTarifas.css';

export const AjusteTarifas = ({ tarifas, cargandoTarifas, errorTarifas, agregarNuevaTarifa, actualizarTarifaExistente, eliminarTarifaExistente }) => {
  const [tarifaEditando, setTarifaEditando] = useState(null);
  const [nuevaTarifa, setNuevaTarifa] = useState({ nombre: '', porcentaje: '' });

  const tarifasOrdenadas = useMemo(() => {
    return [...tarifas].sort((a, b) => parseFloat(b.porcentaje) - parseFloat(a.porcentaje));
  }, [tarifas]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (tarifaEditando) {
      actualizarTarifaExistente(tarifaEditando.id, nuevaTarifa);
      setTarifaEditando(null);
    } else {
      agregarNuevaTarifa(nuevaTarifa);
    }
    setNuevaTarifa({ nombre: '', porcentaje: '' });
  }, [tarifaEditando, nuevaTarifa, actualizarTarifaExistente, agregarNuevaTarifa]);

  const handleEdit = useCallback((tarifa) => {
    setTarifaEditando(tarifa);
    setNuevaTarifa({ nombre: tarifa.nombre, porcentaje: tarifa.porcentaje });
  }, []);

  const handleCancelEdit = useCallback(() => {
    setTarifaEditando(null);
    setNuevaTarifa({ nombre: '', porcentaje: '' });
  }, []);

  const handleDelete = useCallback(async (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta tarifa?')) {
      try {
        await eliminarTarifaExistente(id);
      } catch (error) {
        console.error('Error al eliminar la tarifa:', error);
      }
    }
  }, [eliminarTarifaExistente]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setNuevaTarifa(prev => ({ ...prev, [name]: value }));
  }, []);

  if (cargandoTarifas) return <div className="loading">Cargando tarifas...</div>;
  if (errorTarifas) return <div className="error">Error al cargar las tarifas: {errorTarifas}</div>;

  return (
    <div className="ajuste-tarifas">
      <h2>{tarifaEditando ? 'Editar Tarifa' : 'Agregar Nueva Tarifa'}</h2>
      
      <div className="info-tarifas">
        <FaInfoCircle className="info-icon" />
        <p>
          Las tarifas permiten aplicar diferentes porcentajes de ganancia a los productos. 
          Cada tarifa tiene un nombre y un porcentaje asociado. Al asignar una tarifa a un producto, 
          se calculará automáticamente su precio de venta basado en el costo y el porcentaje de la tarifa. 
          Esto facilita la gestión de precios para diferentes categorías o situaciones de venta.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="tarifa-form">
        <input
          type="text"
          name="nombre"
          placeholder="Nombre de la tarifa"
          value={nuevaTarifa.nombre}
          onChange={handleInputChange}
          required
        />
        <input
          type="number"
          name="porcentaje"
          placeholder="Porcentaje"
          value={nuevaTarifa.porcentaje}
          onChange={handleInputChange}
          required
        />
        <button type="submit" className="btn-primary">
          {tarifaEditando ? <><FaSave /> Actualizar</> : <><FaPlus /> Agregar</>}
        </button>
        {tarifaEditando && (
          <button type="button" onClick={handleCancelEdit} className="btn-secondary">
            <FaTimes /> Cancelar
          </button>
        )}
      </form>
      <h3>Lista de Tarifas</h3>
      <div className="lista-tarifas">
        {tarifasOrdenadas.length === 0 ? (
          <p>No hay tarifas disponibles. Agrega una nueva tarifa.</p>
        ) : (
          tarifasOrdenadas.map((tarifa) => (
            <div key={tarifa.id} className="tarifa-item">
              <span className="tarifa-nombre">{tarifa.nombre}</span>
              <span className="tarifa-porcentaje"><FaPercent /> {tarifa.porcentaje}%</span>
              <div className="tarifa-acciones">
                <button onClick={() => handleEdit(tarifa)} className="btn-edit"><FaEdit /></button>
                <button onClick={() => handleDelete(tarifa.id)} className="btn-delete"><FaTrash /></button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};