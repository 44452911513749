import React, { useState } from 'react';
import { FaSave } from 'react-icons/fa';

export const GestionAlertas = ({ umbrales, actualizarUmbrales }) => {
  const [umbralStockBajo, setUmbralStockBajo] = useState(umbrales.STOCK_BAJO);
  const [umbralRentabilidadBaja, setUmbralRentabilidadBaja] = useState(umbrales.RENTABILIDAD_BAJA);
  const [umbralVentaAlta, setUmbralVentaAlta] = useState(umbrales.VENTA_ALTA);

  const handleSubmit = (e) => {
    e.preventDefault();
    actualizarUmbrales({
      STOCK_BAJO: Number(umbralStockBajo),
      RENTABILIDAD_BAJA: Number(umbralRentabilidadBaja),
      VENTA_ALTA: Number(umbralVentaAlta)
    });
  };

  return (
    <div className="gestion-alertas">
      <h2>Gestión de Alertas</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="umbralStockBajo">Umbral de Stock Bajo:</label>
          <input
            type="number"
            id="umbralStockBajo"
            value={umbralStockBajo}
            onChange={(e) => setUmbralStockBajo(e.target.value)}
            min="0"
          />
          <p className="descripcion">Se generará una alerta cuando el stock de un producto sea menor a este valor.</p>
        </div>
        <div className="form-group">
          <label htmlFor="umbralRentabilidadBaja">Umbral de Rentabilidad Baja (%):</label>
          <input
            type="number"
            id="umbralRentabilidadBaja"
            value={umbralRentabilidadBaja}
            onChange={(e) => setUmbralRentabilidadBaja(e.target.value)}
            min="0"
            max="100"
          />
          <p className="descripcion">Se generará una alerta cuando la rentabilidad de un producto sea menor a este porcentaje.</p>
        </div>
        <div className="form-group">
          <label htmlFor="umbralVentaAlta">Umbral de Venta Alta:</label>
          <input
            type="number"
            id="umbralVentaAlta"
            value={umbralVentaAlta}
            onChange={(e) => setUmbralVentaAlta(e.target.value)}
            min="0"
          />
          <p className="descripcion">Se generará una notificación cuando el precio de venta de un producto supere este valor.</p>
        </div>
        <button type="submit" className="btn btn-primary">
          <FaSave /> Guardar Configuración
        </button>
      </form>
    </div>
  );
};