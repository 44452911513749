import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement } from 'chart.js';
import './styles/global.css';
import './styles/Header.css';
import './styles/ListaProductos.css';
import './styles/ProductoItem.css';
import './styles/Resumen.css';
import './styles/Notificaciones.css';
import './styles/FormularioProducto.css';
import './styles/GestionAlertas.css';
import './styles/SplashScreen.css';
import { v4 as uuidv4 } from 'uuid';
import { VistaInventario, actualizarInventario as actualizarInventarioFunc } from './components/Inventario';
import { ListaProductos } from './pages/ListaProductos';
import { BusquedaAvanzada } from './pages/BusquedaAvanzada';
import { FormularioProducto } from './pages/FormularioProducto';
import { DetallesProducto } from './pages/DetallesProducto';
import { Resumen } from './pages/Resumen';
import { Categorias } from './components/Categorias';
import { Historial } from './components/Historial';
import { FaList, FaChartBar, FaTags, FaBoxes, FaHistory, FaSun, FaMoon, FaImage, FaUser, FaCog } from 'react-icons/fa';
import { Notificaciones } from './components/Notificaciones';
import { verificarAlertasProducto, UMBRALES } from './utils/alertasUtils';
import { GestionAlertas } from './pages/GestionAlertas';
import { exportarCSV } from './utils/exportUtils';
import { useProductos } from './hooks/useProductos';
import { SplashScreen } from './components/SplashScreen';
import { onSnapshot, collection } from 'firebase/firestore';
import { db } from './firebase/config';
import ConexionIndicador from './components/ConexionIndicador';
import { AjusteTarifas } from './pages/AjusteTarifas';
import { useTarifas } from './hooks/useTarifas';
import { calcularRentabilidad } from './utils/calculos';
import { Navegacion } from './components/Navegacion';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement);

function App() {
  const {
    productos,
    cargando,
    error,
    agregarNuevoProducto,
    actualizarProductoExistente,
    eliminarProductoExistente,
    actualizarTodosLosProductos
  } = useProductos();

  const {
    tarifas,
    cargandoTarifas,
    errorTarifas,
    agregarNuevaTarifa,
    actualizarTarifaExistente,
    eliminarTarifaExistente
  } = useTarifas();

  const [mostrarSplash, setMostrarSplash] = useState(true);

  const [productoActual, setProductoActual] = useState({
    nombre: '',
    categoria: '',
    precioVenta: '',
    costoProducto: '',
    cantidadVendida: '',
    gastosGenerales: '',
    impuestos: '',
    imagen: '',
    tarifaId: ''
  });
  const [modoEdicion, setModoEdicion] = useState(false);
  const [vistaActual, setVistaActual] = useState('lista');
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const [busqueda, setBusqueda] = useState('');
  const [filtroCategoria, setFiltroCategoria] = useState('');
  const [modoOscuro, setModoOscuro] = useState(false);
  const [ordenProductos, setOrdenProductos] = useState({ campo: 'nombre', direccion: 'asc' });
  const [busquedaAvanzada, setBusquedaAvanzada] = useState({
    precioMin: '',
    precioMax: '',
    rentabilidadMin: '',
    rentabilidadMax: ''
  });
  const [notificaciones, setNotificaciones] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [inventario, setInventario] = useState({});
  const [alertas, setAlertas] = useState([]);
  const [historial, setHistorial] = useState([]);
  const [umbrales, setUmbrales] = useState(UMBRALES);
  const [colaNotificaciones, setColaNotificaciones] = useState([]);
  const [notificacionActual, setNotificacionActual] = useState(null);
  const [nombreUsuario, setNombreUsuario] = useState('Usuario');
  const [estaConectado, setEstaConectado] = useState(false);

  useEffect(() => {
    const productosRef = collection(db, 'productos');
    const unsubscribe = onSnapshot(productosRef, 
      (snapshot) => {
        setEstaConectado(true);
        // Aquí puedes actualizar los productos si lo deseas
      },
      (error) => {
        console.error("Error en la conexión Firestore:", error);
        setEstaConectado(false);
      }
    );

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const modoOscuroGuardado = JSON.parse(localStorage.getItem('modoOscuro')) || false;
    setModoOscuro(modoOscuroGuardado);
    const categoriasGuardadas = JSON.parse(localStorage.getItem('categorias')) || [];
    setCategorias(categoriasGuardadas);
    const inventarioGuardado = JSON.parse(localStorage.getItem('inventario')) || {};
    setInventario(inventarioGuardado);
    const historialGuardado = JSON.parse(localStorage.getItem('historial')) || [];
    setHistorial(historialGuardado);
    const umbralesGuardados = JSON.parse(localStorage.getItem('umbrales')) || UMBRALES;
    setUmbrales(umbralesGuardados);
  }, []);

  useEffect(() => {
    localStorage.setItem('modoOscuro', JSON.stringify(modoOscuro));
    if (modoOscuro) {
      document.documentElement.classList.add('modo-oscuro');
    } else {
      document.documentElement.classList.remove('modo-oscuro');
    }
  }, [modoOscuro]);

  useEffect(() => {
    localStorage.setItem('categorias', JSON.stringify(categorias));
  }, [categorias]);

  useEffect(() => {
    localStorage.setItem('inventario', JSON.stringify(inventario));
  }, [inventario]);

  useEffect(() => {
    localStorage.setItem('historial', JSON.stringify(historial));
  }, [historial]);

  useEffect(() => {
    if (colaNotificaciones.length > 0 && !notificacionActual) {
      const siguienteNotificacion = colaNotificaciones[0];
      setNotificacionActual(siguienteNotificacion);
      setColaNotificaciones(prev => prev.slice(1));
      setTimeout(() => {
        setNotificacionActual(null);
      }, 5000);
    }
  }, [colaNotificaciones, notificacionActual]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductoActual(prevProducto => ({
      ...prevProducto,
      [name]: value
    }));
  };

  const agregarProducto = () => {
    if (productoActual.nombre && productoActual.precioVenta && productoActual.costoProducto && productoActual.cantidadVendida) {
      const nuevoProducto = calcularRentabilidad(productoActual);
      if (modoEdicion) {
        actualizarProductoExistente(productoActual.id, nuevoProducto);
        agregarNotificacion("Producto actualizado con éxito", "success");
      } else {
        agregarNuevoProducto(nuevoProducto);
        agregarNotificacion("Producto agregado con éxito", "success");
      }
      setProductoActual({
        id: '',
        nombre: '',
        categoria: '',
        precioVenta: '',
        costoProducto: '',
        cantidadVendida: '',
        gastosGenerales: '',
        impuestos: '',
        imagen: '',
        tarifaId: ''
      });
      setModoEdicion(false);
      setVistaActual('lista');
    }
  };

  const editarProducto = (producto) => {
    setProductoActual({
      id: producto.id || '',
      nombre: producto.nombre || '',
      categoria: producto.categoria || '',
      precioVenta: producto.precioVenta || '',
      costoProducto: producto.costoProducto || '',
      cantidadVendida: producto.cantidadVendida || '',
      gastosGenerales: producto.gastosGenerales || '',
      impuestos: producto.impuestos || '',
      imagen: producto.imagen || '',
      tarifaId: producto.tarifaId || ''
    });
    setModoEdicion(true);
    setVistaActual('formulario');
  };

  const eliminarProducto = (id) => {
    eliminarProductoExistente(id);
    agregarNotificacion("Producto eliminado con éxito", "success");
  };

  const mostrarFormulario = () => {
    setVistaActual('formulario');
    setProductoActual({
      nombre: '',
      categoria: '',
      precioVenta: '',
      costoProducto: '',
      cantidadVendida: '',
      gastosGenerales: '',
      impuestos: '',
      imagen: '',
      tarifaId: ''
    });
    setModoEdicion(false);
  };

  const mostrarDetalles = (producto, index) => {
    setProductoSeleccionado({ ...producto, index });
    setVistaActual('detalles');
  };

  const aplicarBusquedaAvanzada = useCallback((productos) => {
    return productos.filter(p => {
      if (!p) return false;
      const precio = parseFloat(p.precioVenta) || 0;
      const rentabilidad = parseFloat(p.rentabilidad) || 0;
      return (
        (!busquedaAvanzada.precioMin || precio >= parseFloat(busquedaAvanzada.precioMin)) &&
        (!busquedaAvanzada.precioMax || precio <= parseFloat(busquedaAvanzada.precioMax)) &&
        (!busquedaAvanzada.rentabilidadMin || rentabilidad >= parseFloat(busquedaAvanzada.rentabilidadMin)) &&
        (!busquedaAvanzada.rentabilidadMax || rentabilidad <= parseFloat(busquedaAvanzada.rentabilidadMax))
      );
    });
  }, [busquedaAvanzada]);

  const productosFiltradosYOrdenados = useMemo(() => {
    if (!Array.isArray(productos)) return [];

    let productosFiltrados = productos.filter(p => 
      p && p.nombre && p.nombre.toLowerCase().includes(busqueda.toLowerCase()) &&
      (filtroCategoria === '' || p.categoria === filtroCategoria)
    );
    
    productosFiltrados = aplicarBusquedaAvanzada(productosFiltrados);

    return productosFiltrados.sort((a, b) => {
      if (a[ordenProductos.campo] < b[ordenProductos.campo]) return ordenProductos.direccion === 'asc' ? -1 : 1;
      if (a[ordenProductos.campo] > b[ordenProductos.campo]) return ordenProductos.direccion === 'asc' ? 1 : -1;
      return 0;
    });
  }, [productos, busqueda, filtroCategoria, ordenProductos, aplicarBusquedaAvanzada]);

  const datosGrafico = {
    labels: productosFiltradosYOrdenados.map(p => p.nombre),
    datasets: [
      {
        label: 'Rentabilidad (%)',
        data: productosFiltradosYOrdenados.map(p => parseFloat(p.rentabilidadSobreVentas)),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const opcionesGrafico = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Rentabilidad por Producto',
        font: {
          size: 18
        }
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Rentabilidad (%)'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Productos'
        }
      }
    }
  };

  const calcularEstadisticasAvanzadas = (productos, inventario) => {
    const totalProductos = productos.length;
    const rentabilidadPromedio = productos.reduce((acc, p) => acc + parseFloat(p.rentabilidadSobreVentas), 0) / totalProductos;
    const productoMasRentable = productos.reduce((max, p) => parseFloat(p.rentabilidadSobreVentas) > parseFloat(max.rentabilidadSobreVentas) ? p : max, productos[0]);
    const beneficioTotal = productos.reduce((sum, p) => sum + parseFloat(p.beneficioNeto), 0);
    const ventasTotal = productos.reduce((sum, p) => sum + parseFloat(p.ingresoTotal), 0);
    
    const categoriasMasRentables = Object.entries(
      productos.reduce((acc, p) => {
        acc[p.categoria] = (acc[p.categoria] || 0) + parseFloat(p.beneficioNeto);
        return acc;
      }, {})
    ).sort((a, b) => b[1] - a[1]).slice(0, 5);

    const inventarioTotal = Object.values(inventario).reduce((sum, cantidad) => sum + cantidad, 0);
    const productosBajoStock = Object.entries(inventario).filter(([, cantidad]) => cantidad < 5).length;
    
    return { totalProductos, rentabilidadPromedio, productoMasRentable, beneficioTotal, ventasTotal, categoriasMasRentables, inventarioTotal, productosBajoStock };
  };

  const exportarDatos = (formato) => {
    if (formato === 'json') {
      const dataStr = JSON.stringify(productos);
      const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      const exportFileDefaultName = 'productos.json';
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    } else if (formato === 'csv') {
      exportarCSV(productos);
    }
  };

  const importarDatos = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const contenido = e.target.result;
      try {
        const productosImportados = JSON.parse(contenido);
        actualizarTodosLosProductos(productosImportados);
        agregarNotificacion("Datos importados con éxito", "success");
      } catch (error) {
        console.error("Error al importar los datos:", error);
        agregarNotificacion("Error al importar los datos", "error");
      }
    };
    reader.readAsText(file);
  };

  const toggleModoOscuro = () => {
    setModoOscuro(!modoOscuro);
  };

  const datosGraficoPie = {
    labels: productos.map(p => p.nombre),
    datasets: [
      {
        data: productos.map(p => parseFloat(p.beneficioTotal)),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
        ],
      },
    ],
  };

  const opcionesGraficoPie = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Distribución de Beneficios',
      },
    },
  };

  const ordenarProductos = (campo) => {
    setOrdenProductos(prevOrden => ({
      campo,
      direccion: prevOrden.campo === campo && prevOrden.direccion === 'asc' ? 'desc' : 'asc'
    }));
  };

  const agregarNotificacion = useCallback((mensaje, tipo = 'info') => {
    const nuevaNotificacion = { id: Date.now(), mensaje, tipo };
    setColaNotificaciones(prev => [...prev, nuevaNotificacion]);
  }, []);

  const agregarCategoria = (nombreCategoria) => {
    if (!categorias.includes(nombreCategoria)) {
      setCategorias([...categorias, nombreCategoria]);
      agregarAlHistorial('Categoría añadida', nombreCategoria);
    }
  };

  const verificarAlertasInventario = (productoId, cantidadActual) => {
    if (cantidadActual < 5) {
      agregarAlerta(`Stock bajo para el producto ${productoId}: ${cantidadActual} unidades`, 'warning');
    }
  };

  const actualizarInventario = (productoId, cantidad) => {
    actualizarInventarioFunc(inventario, setInventario, productoId, cantidad, agregarAlHistorial, verificarAlertasInventario);
  };

  const agregarAlerta = (mensaje, tipo = 'info') => {
    const nuevaAlerta = { id: uuidv4(), mensaje, tipo, fecha: new Date() };
    setAlertas(prevAlertas => [...prevAlertas, nuevaAlerta]);
  };

  const agregarAlHistorial = (accion, detalles) => {
    const nuevoEvento = { id: uuidv4(), accion, detalles, fecha: new Date() };
    setHistorial(prevHistorial => [nuevoEvento, ...prevHistorial].slice(0, 100)); // Limitamos a 100 eventos
  };

  const verificarAlertas = useCallback(() => {
    const nuevasAlertas = productos.flatMap(producto => 
      verificarAlertasProducto(producto, inventario, umbrales)
    );
    setAlertas(nuevasAlertas);
  }, [productos, inventario, umbrales]);

  useEffect(() => {
    verificarAlertas();
  }, [verificarAlertas]);

  const actualizarUmbrales = (nuevosUmbrales) => {
    setUmbrales(nuevosUmbrales);
    localStorage.setItem('umbrales', JSON.stringify(nuevosUmbrales));
    // Volver a verificar las alertas con los nuevos umbrales
    verificarAlertas();
  };

  const actualizarDatosPorFecha = (fechaInicio, fechaFin) => {
    // Aquí debes implementar la lógica para actualizar los datos
    // basándote en el rango de fechas seleccionado
    console.log('Actualizando datos desde', fechaInicio, 'hasta', fechaFin);
    // Por ejemplo, podrías llamar a una API o filtrar los datos localmente
  };

  const renderizarVista = () => {
    switch (vistaActual) {
      case 'lista':
        return (
          <ListaProductos 
            busqueda={busqueda} 
            setBusqueda={setBusqueda} 
            filtroCategoria={filtroCategoria} 
            setFiltroCategoria={setFiltroCategoria} 
            categorias={categorias} 
            setVistaActual={setVistaActual} 
            ordenProductos={ordenProductos} 
            ordenarProductos={ordenarProductos} 
            productosFiltradosYOrdenados={productosFiltradosYOrdenados} 
            mostrarDetalles={mostrarDetalles} 
            mostrarFormulario={mostrarFormulario} 
            editarProducto={editarProducto}
            eliminarProducto={eliminarProducto}
          />
        );
      case 'busquedaAvanzada':
        return (
          <BusquedaAvanzada 
            busquedaAvanzada={busquedaAvanzada} 
            setBusquedaAvanzada={setBusquedaAvanzada} 
            setVistaActual={setVistaActual} 
          />
        );
      case 'formulario':
        return (
          <FormularioProducto 
            productoActual={productoActual} 
            handleInputChange={handleInputChange} 
            agregarProducto={agregarProducto} 
            modoEdicion={modoEdicion} 
            setVistaActual={setVistaActual} 
            agregarNotificacion={agregarNotificacion}
          />
        );
      case 'detalles':
        return (
          <DetallesProducto 
            productoSeleccionado={productoSeleccionado} 
            editarProducto={editarProducto} 
            eliminarProducto={eliminarProducto} 
            setVistaActual={setVistaActual} 
            tarifas={tarifas}
          />
        );
      case 'resumen':
        return (
          <Resumen 
            calcularEstadisticasAvanzadas={() => calcularEstadisticasAvanzadas(productos, inventario)} 
            opcionesGrafico={opcionesGrafico} 
            datosGrafico={datosGrafico} 
            opcionesGraficoPie={opcionesGraficoPie} 
            datosGraficoPie={datosGraficoPie} 
            exportarDatos={exportarDatos} 
            importarDatos={importarDatos} 
            setVistaActual={setVistaActual} 
            productos={productos}
            actualizarDatosPorFecha={actualizarDatosPorFecha}
          />
        );
      case 'categorias':
        return (
          <Categorias 
            categorias={categorias} 
            agregarCategoria={agregarCategoria} 
          />
        );
      case 'inventario':
        return (
          <VistaInventario 
            inventario={inventario} 
            actualizarInventario={actualizarInventario} 
          />
        );
      case 'historial':
        return (
          <Historial 
            historial={historial} 
          />
        );
      case 'gestionAlertas':
        return (
          <GestionAlertas 
            umbrales={umbrales}
            actualizarUmbrales={actualizarUmbrales}
          />
        );
      case 'ajusteTarifas':
        return (
          <AjusteTarifas 
            tarifas={tarifas}
            cargandoTarifas={cargandoTarifas}
            errorTarifas={errorTarifas}
            agregarNuevaTarifa={agregarNuevaTarifa}
            actualizarTarifaExistente={actualizarTarifaExistente}
            eliminarTarifaExistente={eliminarTarifaExistente}
          />
        );
      default:
        return null;
    }
  };

  const cerrarNotificacion = (id) => {
    setNotificaciones(prevNotificaciones => prevNotificaciones.filter(notif => notif.id !== id));
    setAlertas(prevAlertas => prevAlertas.filter(alerta => alerta.id !== id));
  };

  useEffect(() => {
    // Simular un tiempo de carga
    const timer = setTimeout(() => {
      setMostrarSplash(false);
    }, 2000); // Ajusta este tiempo según tus necesidades

    return () => clearTimeout(timer);
  }, []);

  if (mostrarSplash || cargando) {
    return <SplashScreen />;
  }

  return (
    <div className={`App ${modoOscuro ? 'modo-oscuro' : ''}`}>
      <header className="header">
        <div className="header-content">
          <h1 className="app-title">Rentabilidad Pro</h1>
          <ConexionIndicador estaConectado={estaConectado} />
          <div className="user-greeting">
            <FaUser />
            <span>{nombreUsuario}</span>
          </div>
          <button className="modo-oscuro-toggle" onClick={toggleModoOscuro} aria-label="Cambiar modo oscuro">
            {modoOscuro ? <FaSun /> : <FaMoon />}
          </button>
        </div>
        <Navegacion vistaActual={vistaActual} setVistaActual={setVistaActual} />
      </header>
      <main className="main-content">
        {cargando || cargandoTarifas ? (
          <div>Cargando...</div>
        ) : error || errorTarifas ? (
          <div>Error: {error || errorTarifas}</div>
        ) : (
          renderizarVista()
        )}
      </main>
      <Notificaciones notificaciones={[...notificaciones, ...alertas]} cerrarNotificacion={cerrarNotificacion} />
    </div>
  );
}

export default App;
