// Este archivo contiene todas las funciones relacionadas con el CRUD de productos

import { db } from '../firebase/config';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';

const productosRef = collection(db, 'productos');

/**
 * Obtiene todos los productos del almacenamiento local
 * @returns {Array} Un array de productos
 */
export const obtenerProductos = async () => {
  try {
    const snapshot = await getDocs(productosRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error al obtener productos:", error);
    return []; // Devuelve un array vacío en caso de error
  }
};

/**
 * Agrega un nuevo producto al almacenamiento local
 * @param {Object} producto - El producto a agregar
 * @returns {string} El ID del nuevo producto
 */
export const agregarProducto = async (producto) => {
  const docRef = await addDoc(productosRef, producto);
  return docRef.id;
};

/**
 * Actualiza un producto existente en el almacenamiento local
 * @param {string} id - El ID del producto a actualizar
 * @param {Object} producto - Los nuevos datos del producto
 */
export const actualizarProducto = async (id, producto) => {
  try {
    if (!id || typeof id !== 'string') {
      throw new Error('ID del producto no válido');
    }
    const docRef = doc(db, 'productos', id);
    
    // Filtrar campos undefined, null o vacíos
    const productoFiltrado = Object.entries(producto).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});

    await updateDoc(docRef, productoFiltrado);
  } catch (error) {
    console.error("Error al actualizar el producto:", error);
    throw error;
  }
};

/**
 * Elimina un producto del almacenamiento local
 * @param {string} id - El ID del producto a eliminar
 */
export const eliminarProducto = async (id) => {
  try {
    if (!id || typeof id !== 'string') {
      throw new Error('ID del producto no válido');
    }
    const docRef = doc(db, 'productos', id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error("Error al eliminar el producto:", error);
    throw error;
  }
};