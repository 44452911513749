// Este hook personalizado maneja la lógica de estado y las operaciones CRUD para productos

import { useState, useEffect, useCallback } from 'react';
import { obtenerProductos, agregarProducto, actualizarProducto, eliminarProducto } from '../services/productosService';
import { collection, onSnapshot, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase/config'; // Importamos la instancia de db

export function useProductos() {
  const [productos, setProductos] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const productosRef = collection(db, 'productos');
    const unsubscribe = onSnapshot(productosRef, 
      (snapshot) => {
        const nuevosProductos = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProductos(nuevosProductos);
        setCargando(false);
      },
      (error) => {
        console.error("Error al obtener productos:", error);
        setError(error);
        setCargando(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const agregarNuevoProducto = useCallback((nuevoProducto) => {
    try {
      const id = agregarProducto(nuevoProducto);
      setProductos(prevProductos => [...prevProductos, { ...nuevoProducto, id }]);
    } catch (err) {
      setError('Error al agregar el producto');
      console.error(err);
    }
  }, []);

  const actualizarProductoExistente = useCallback(async (id, productoActualizado) => {
    try {
      if (!id || typeof id !== 'string') {
        throw new Error('ID del producto no válido');
      }
      await actualizarProducto(id, productoActualizado);
      setProductos(prevProductos =>
        prevProductos.map(p => p.id === id ? { ...p, ...productoActualizado } : p)
      );
    } catch (err) {
      setError(`Error al actualizar el producto: ${err.message}`);
      console.error(err);
    }
  }, []);

  const eliminarProductoExistente = useCallback(async (id) => {
    try {
      await eliminarProducto(id);
      setProductos(prevProductos => prevProductos.filter(p => p.id !== id));
    } catch (err) {
      setError(`Error al eliminar el producto: ${err.message}`);
      console.error(err);
      throw err; // Re-lanzamos el error para que pueda ser manejado por el componente
    }
  }, []);

  const actualizarTodosLosProductos = useCallback((nuevosProductos) => {
    localStorage.setItem('productos', JSON.stringify(nuevosProductos));
    setProductos(nuevosProductos);
  }, []);

  return {
    productos,
    cargando,
    error,
    agregarNuevoProducto,
    actualizarProductoExistente,
    eliminarProductoExistente,
    actualizarTodosLosProductos
  };
}