export const exportarCSV = (datos) => {
  const encabezados = Object.keys(datos[0]).join(',');
  const filas = datos.map(objeto => Object.values(objeto).join(','));
  const contenidoCSV = [encabezados, ...filas].join('\n');
  
  const blob = new Blob([contenidoCSV], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "productos.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};