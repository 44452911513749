import React from 'react';
import '../styles/SplashScreen.css';

export const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <div className="splash-content">
        <div className="logo-container">
          <div className="logo">RP</div>
        </div>
        <h1>Rentabilidad Pro</h1>
        <div className="loader">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <p>Cargando datos...</p>
      </div>
    </div>
  );
};