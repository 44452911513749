import React from 'react';
import { FaList, FaChartBar, FaTags, FaBoxes, FaHistory, FaImage, FaCog } from 'react-icons/fa';

export const Navegacion = ({ vistaActual, setVistaActual }) => {
  const navItems = [
    { id: 'lista', icon: FaList, label: 'Lista' },
    { id: 'resumen', icon: FaChartBar, label: 'Resumen' },
    { id: 'categorias', icon: FaTags, label: 'Categorías' },
    { id: 'inventario', icon: FaBoxes, label: 'Inventario' },
    { id: 'historial', icon: FaHistory, label: 'Historial' },
    { id: 'gestionAlertas', icon: FaImage, label: 'Alertas' },
    { id: 'ajusteTarifas', icon: FaCog, label: 'Tarifas' }
  ];

  return (
    <nav className="main-nav">
      {navItems.map(item => (
        <button
          key={item.id}
          className={`nav-btn ${vistaActual === item.id ? 'active' : ''}`}
          onClick={() => setVistaActual(item.id)}
          aria-label={item.label}
        >
          <item.icon />
          <span className="nav-text">{item.label}</span>
        </button>
      ))}
    </nav>
  );
};