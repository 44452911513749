export const UMBRALES = {
  STOCK_BAJO: 5,
  RENTABILIDAD_BAJA: 10,
  VENTA_ALTA: 1000
};

export const verificarAlertasProducto = (producto, inventario) => {
  const alertas = [];

  // Alerta de stock bajo
  const stockActual = inventario[producto.nombre] || 0;
  if (stockActual < UMBRALES.STOCK_BAJO) {
    alertas.push({
      tipo: 'warning',
      mensaje: `Stock bajo para ${producto.nombre}: ${stockActual} unidades`
    });
  }

  // Alerta de rentabilidad baja
  if (parseFloat(producto.rentabilidad) < UMBRALES.RENTABILIDAD_BAJA) {
    alertas.push({
      tipo: 'warning',
      mensaje: `Baja rentabilidad para ${producto.nombre}: ${producto.rentabilidad}%`
    });
  }

  // Alerta de venta alta
  if (parseFloat(producto.precioVenta) > UMBRALES.VENTA_ALTA) {
    alertas.push({
      tipo: 'info',
      mensaje: `Venta alta para ${producto.nombre}: $${producto.precioVenta}`
    });
  }

  return alertas;
};