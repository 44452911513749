import React, { useState, useEffect } from 'react';
import '../styles/FormularioProducto.css';

export const FormularioProducto = ({ productoActual, handleInputChange, agregarProducto, modoEdicion, setVistaActual, agregarNotificacion }) => {
  const [pasoActual, setPasoActual] = useState(1);
  const totalPasos = 4;

  useEffect(() => {
    // Reiniciar el paso actual cuando cambia el modo de edición
    setPasoActual(1);
  }, [modoEdicion]);

  const avanzarPaso = () => {
    if (pasoActual < totalPasos) {
      setPasoActual(pasoActual + 1);
    }
  };

  const retrocederPaso = () => {
    if (pasoActual > 1) {
      setPasoActual(pasoActual - 1);
    }
  };

  const renderizarPaso = () => {
    switch (pasoActual) {
      case 1:
        return (
          <div className="paso activo">
            <h3>Información básica</h3>
            <div className="form-group">
              <label htmlFor="nombre">Nombre del producto</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={productoActual.nombre}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="categoria">Categoría</label>
              <input
                type="text"
                id="categoria"
                name="categoria"
                value={productoActual.categoria}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="paso activo">
            <h3>Precios</h3>
            <div className="form-group">
              <label htmlFor="precioVenta">Precio de venta</label>
              <input
                type="number"
                id="precioVenta"
                name="precioVenta"
                value={productoActual.precioVenta}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="costoProducto">Costo del producto</label>
              <input
                type="number"
                id="costoProducto"
                name="costoProducto"
                value={productoActual.costoProducto}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="paso activo">
            <h3>Ventas y gastos</h3>
            <div className="form-group">
              <label htmlFor="cantidadVendida">Cantidad vendida</label>
              <input
                type="number"
                id="cantidadVendida"
                name="cantidadVendida"
                value={productoActual.cantidadVendida}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="gastosGenerales">Gastos generales</label>
              <input
                type="number"
                id="gastosGenerales"
                name="gastosGenerales"
                value={productoActual.gastosGenerales}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="impuestos">Impuestos (%)</label>
              <input
                type="number"
                id="impuestos"
                name="impuestos"
                value={productoActual.impuestos}
                onChange={handleInputChange}
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="paso activo">
            <h3>Imagen del producto</h3>
            <div className="form-group">
              <label htmlFor="imagen">URL de la imagen</label>
              <input
                type="text"
                id="imagen"
                name="imagen"
                value={productoActual.imagen}
                onChange={handleInputChange}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    agregarProducto();
    agregarNotificacion(modoEdicion ? "Producto actualizado con éxito" : "Producto agregado con éxito", "success");
  };

  return (
    <div className="formulario-producto">
      <h2>{modoEdicion ? 'Editar Producto' : 'Agregar Nuevo Producto'}</h2>
      <form onSubmit={handleSubmit}>
        {renderizarPaso()}
        <div className="navegacion-formulario">
          {pasoActual > 1 && (
            <button type="button" className="btn-nav btn-anterior" onClick={retrocederPaso}>
              Anterior
            </button>
          )}
          {pasoActual < totalPasos && (
            <button type="button" className="btn-nav btn-siguiente" onClick={avanzarPaso}>
              Siguiente
            </button>
          )}
          {pasoActual === totalPasos && (
            <button type="submit" className="btn-nav btn-enviar">
              {modoEdicion ? 'Actualizar' : 'Agregar'} Producto
            </button>
          )}
        </div>
      </form>
      <div className="progreso-formulario">
        {Array.from({ length: totalPasos }, (_, i) => (
          <div
            key={i}
            className={`paso-indicador ${i + 1 <= pasoActual ? 'activo' : ''}`}
          ></div>
        ))}
      </div>
    </div>
  );
};