import { useState, useEffect, useCallback } from 'react';
import { obtenerTarifas, agregarTarifa, actualizarTarifa, eliminarTarifa } from '../services/tarifasService';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/config';

export function useTarifas() {
  const [tarifas, setTarifas] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const tarifasRef = collection(db, 'tarifas');
    const unsubscribe = onSnapshot(tarifasRef, 
      (snapshot) => {
        const nuevasTarifas = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTarifas(nuevasTarifas);
        setCargando(false);
      },
      (error) => {
        console.error("Error al obtener tarifas:", error);
        setError(error.message);
        setCargando(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const agregarNuevaTarifa = useCallback(async (nuevaTarifa) => {
    try {
      await agregarTarifa(nuevaTarifa);
      // No actualizamos el estado local aquí, el listener de onSnapshot se encargará de ello
    } catch (err) {
      setError('Error al agregar la tarifa: ' + err.message);
      console.error(err);
    }
  }, []);

  const actualizarTarifaExistente = useCallback(async (id, tarifaActualizada) => {
    try {
      await actualizarTarifa(id, tarifaActualizada);
      // No actualizamos el estado local aquí, el listener de onSnapshot se encargará de ello
    } catch (err) {
      setError(`Error al actualizar la tarifa: ${err.message}`);
      console.error(err);
    }
  }, []);

  const eliminarTarifaExistente = useCallback(async (id) => {
    try {
      await eliminarTarifa(id);
      // No actualizamos el estado local aquí, el listener de onSnapshot se encargará de ello
    } catch (err) {
      setError(`Error al eliminar la tarifa: ${err.message}`);
      console.error(err);
      throw err;
    }
  }, []);

  return {
    tarifas,
    cargando,
    error,
    agregarNuevaTarifa,
    actualizarTarifaExistente,
    eliminarTarifaExistente
  };
}