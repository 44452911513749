import React from 'react';

export const Categorias = ({ categorias, agregarCategoria }) => (
  <div className="categorias">
    <h2>Gestión de Categorías</h2>
    <ul>
      {categorias.map(categoria => (
        <li key={categoria}>{categoria}</li>
      ))}
    </ul>
    <input
      type="text"
      placeholder="Nueva categoría"
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          agregarCategoria(e.target.value);
          e.target.value = '';
        }
      }}
    />
  </div>
);