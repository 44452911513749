import React, { useState } from 'react';
import { Bar, Pie, Line } from 'react-chartjs-2';
import { FaFileExport, FaFileImport, FaArrowLeft, FaBoxes, FaChartLine, FaDollarSign, FaExclamationTriangle, FaTrophy, FaUsers, FaWarehouse, FaChartBar, FaExpandArrowsAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export const Resumen = ({ 
  calcularEstadisticasAvanzadas, 
  opcionesGrafico, 
  datosGrafico, 
  opcionesGraficoPie, 
  datosGraficoPie, 
  exportarDatos, 
  importarDatos, 
  setVistaActual,
  productos,
  clientesNuevos,
  pedidosPendientes,
  actualizarDatosPorFecha
}) => {
  const estadisticas = calcularEstadisticasAvanzadas();

  if (!Array.isArray(productos) || productos.length === 0) {
    return <div>No hay productos para mostrar en el resumen.</div>;
  }

  const { totalProductos, productoMasRentable, beneficioTotal, ventasTotal, categoriasMasRentables, inventarioTotal, productosBajoStock } = estadisticas;
  
  const datosTendenciaVentas = {
    labels: productos.map(p => p.nombre),
    datasets: [
      {
        label: 'Ventas',
        data: productos.map(p => parseFloat(p.precioVenta) * parseFloat(p.cantidadVendida)),
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  const opcionesTendenciaVentas = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Tendencia de Ventas por Producto'
      }
    }
  };

  const [graficoExpandido, setGraficoExpandido] = useState(null);

  const expandirGrafico = (index) => {
    setGraficoExpandido(graficoExpandido === index ? null : index);
  };

  const renderGrafico = (titulo, componente, index) => (
    <div className={`grafico-card ${graficoExpandido === index ? 'expandido' : ''}`}>
      <div className="grafico-header">
        <h3>{titulo}</h3>
        <button onClick={() => expandirGrafico(index)} className="btn-expandir">
          <FaExpandArrowsAlt />
        </button>
      </div>
      {componente}
    </div>
  );

  const kpis = [
    { titulo: "Rotación de inventario", valor: (ventasTotal / inventarioTotal).toFixed(2), unidad: "veces" },
    { titulo: "Margen de beneficio", valor: ((beneficioTotal / ventasTotal) * 100).toFixed(2), unidad: "%" },
    { titulo: "Valor promedio de pedido", valor: (ventasTotal / pedidosPendientes).toFixed(2), unidad: "€" },
    { titulo: "Tasa de conversión", valor: ((pedidosPendientes / clientesNuevos) * 100).toFixed(2), unidad: "%" }
  ];

  const [fechaInicio, setFechaInicio] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [fechaFin, setFechaFin] = useState(new Date());

  const actualizarDatos = () => {
    if (fechaInicio && fechaFin && fechaInicio <= fechaFin) {
      try {
        actualizarDatosPorFecha(fechaInicio, fechaFin);
      } catch (error) {
        console.error('Error al actualizar datos:', error);
      }
    } else {
      console.error('Fechas inválidas');
      // Aquí podrías mostrar un mensaje de error al usuario
    }
  };

  return (
    <div className="resumen">
      <h2>Dashboard de Gestión de Inventario</h2>
      <div className="filtro-fecha">
        <DatePicker selected={fechaInicio} onChange={date => setFechaInicio(date)} />
        <DatePicker selected={fechaFin} onChange={date => setFechaFin(date)} />
        <button onClick={actualizarDatos} className="btn btn-primary">Actualizar datos</button>
      </div>
      <div className="estadisticas-generales">
        <div className="estadistica-card primary">
          <FaDollarSign className="estadistica-icono" />
          <h3>Ventas totales</h3>
          <p>{ventasTotal.toFixed(2)}€</p>
          <small>+5% vs mes anterior</small>
        </div>
        <div className="estadistica-card success">
          <FaChartLine className="estadistica-icono" />
          <h3>Beneficio total</h3>
          <p>{beneficioTotal.toFixed(2)}€</p>
          <small>Margen: {(beneficioTotal / ventasTotal * 100).toFixed(2)}%</small>
        </div>
        <div className="estadistica-card info">
          <FaBoxes className="estadistica-icono" />
          <h3>Total de productos</h3>
          <p>{totalProductos}</p>
          <small>Inventario: {inventarioTotal} unidades</small>
        </div>
        <div className="estadistica-card warning">
          <FaUsers className="estadistica-icono" />
          <h3>Clientes nuevos</h3>
          <p>{clientesNuevos}</p>
          <small>+10% vs mes anterior</small>
        </div>
        <div className="estadistica-card danger">
          <FaExclamationTriangle className="estadistica-icono" />
          <h3>Productos con bajo stock</h3>
          <p>{productosBajoStock}</p>
          <small>{(productosBajoStock / totalProductos * 100).toFixed(2)}% del total</small>
        </div>
        <div className="estadistica-card secondary">
          <FaWarehouse className="estadistica-icono" />
          <h3>Pedidos pendientes</h3>
          <p>{pedidosPendientes}</p>
          <small>Valor: {(pedidosPendientes * ventasTotal / totalProductos).toFixed(2)}€</small>
        </div>
      </div>
      <div className="producto-mas-rentable">
        <FaTrophy className="producto-mas-rentable-icono" />
        <h3>Producto más rentable</h3>
        <p>{productoMasRentable?.nombre}</p>
        <p>Rentabilidad: <strong>{productoMasRentable?.rentabilidad}%</strong></p>
        <p>Ventas: {productoMasRentable?.cantidadVendida} unidades</p>
        <p>Beneficio: {(productoMasRentable?.rentabilidad * productoMasRentable?.cantidadVendida / 100).toFixed(2)}€</p>
      </div>
      <div className="graficos">
        <div className="grafico-row">
          {renderGrafico("Rentabilidad por Producto", <Bar options={opcionesGrafico} data={datosGrafico} />, 0)}
          {renderGrafico("Distribución de Beneficios", <Pie options={opcionesGraficoPie} data={datosGraficoPie} />, 1)}
        </div>
        <div className="grafico-row">
          {renderGrafico("Categorías más rentables", 
            <Bar 
              options={{
                ...opcionesGrafico,
                indexAxis: 'y',
              }} 
              data={{
                labels: categoriasMasRentables.map(([cat]) => cat),
                datasets: [{
                  label: 'Beneficio total',
                  data: categoriasMasRentables.map(([, beneficio]) => beneficio),
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                }]
              }} 
            />, 2)}
          {renderGrafico("Tendencia de Ventas", <Line options={opcionesTendenciaVentas} data={datosTendenciaVentas} />, 3)}
        </div>
      </div>
      <div className="acciones-resumen">
        <div className="dropdown">
          <button className="btn btn-primary dropdown-toggle">
            <FaFileExport /> Exportar Datos
          </button>
          <div className="dropdown-content">
            <button onClick={() => exportarDatos('json')}>JSON</button>
            <button onClick={() => exportarDatos('csv')}>CSV</button>
            <button onClick={() => exportarDatos('pdf')}>PDF</button>
          </div>
        </div>
        <label className="btn btn-secondary">
          <FaFileImport /> Importar Datos
          <input type="file" onChange={importarDatos} accept=".json,.csv" style={{display: 'none'}} />
        </label>
        <button className="btn btn-info" onClick={() => setVistaActual('analisis')}>
          <FaChartBar /> Análisis Avanzado
        </button>
        <button className="btn btn-back" onClick={() => setVistaActual('lista')}>
          <FaArrowLeft /> Volver a la Lista
        </button>
      </div>
      <div className="kpis">
        {kpis.map((kpi, index) => (
          <div key={index} className="kpi-card">
            <h3>{kpi.titulo}</h3>
            <p className="kpi-valor">{kpi.valor}<span className="kpi-unidad">{kpi.unidad}</span></p>
          </div>
        ))}
      </div>
    </div>
  );
};