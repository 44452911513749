import React from 'react';
import { FaTimes } from 'react-icons/fa';

export const Notificaciones = ({ notificaciones, cerrarNotificacion }) => {
  return (
    <div className="notificaciones">
      {notificaciones.map(notif => (
        <div key={notif.id} className={`notificacion ${notif.tipo}`}>
          {notif.mensaje}
          <button onClick={() => cerrarNotificacion(notif.id)} className="cerrar-notificacion">
            <FaTimes />
          </button>
        </div>
      ))}
    </div>
  );
};