import React, { useState } from 'react';
import { FaSearch, FaEuroSign, FaChartLine, FaTimes, FaPercent, FaExchangeAlt, FaChartBar } from 'react-icons/fa';
import './BusquedaAvanzada.css';

export const BusquedaAvanzada = ({ busquedaAvanzada, setBusquedaAvanzada, setVistaActual }) => {
  const [errores, setErrores] = useState({});

  const validarCampo = (nombre, valor) => {
    if (valor && isNaN(parseFloat(valor))) {
      setErrores(prev => ({ ...prev, [nombre]: 'Debe ser un número válido' }));
    } else {
      setErrores(prev => {
        const nuevoErrores = { ...prev };
        delete nuevoErrores[nombre];
        return nuevoErrores;
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBusquedaAvanzada({ ...busquedaAvanzada, [name]: value });
    validarCampo(name, value);
  };

  const aplicarFiltros = () => {
    if (Object.keys(errores).length === 0) {
      setVistaActual('lista');
    }
  };

  return (
    <div className="busqueda-avanzada">
      <h2 className="titulo"><FaSearch /> Búsqueda Avanzada de Rentabilidad</h2>
      <form className="formulario-busqueda">
        <div className="grupo-inputs">
          <div className="input-group">
            <label htmlFor="precioMin"><FaEuroSign /> Precio Mínimo</label>
            <input
              id="precioMin"
              name="precioMin"
              type="number"
              value={busquedaAvanzada.precioMin}
              onChange={handleChange}
              className={errores.precioMin ? 'error' : ''}
              placeholder="0"
            />
            {errores.precioMin && <span className="error-mensaje">{errores.precioMin}</span>}
          </div>
          <div className="input-group">
            <label htmlFor="precioMax"><FaEuroSign /> Precio Máximo</label>
            <input
              id="precioMax"
              name="precioMax"
              type="number"
              value={busquedaAvanzada.precioMax}
              onChange={handleChange}
              className={errores.precioMax ? 'error' : ''}
              placeholder="1000"
            />
            {errores.precioMax && <span className="error-mensaje">{errores.precioMax}</span>}
          </div>
        </div>
        <div className="grupo-inputs">
          <div className="input-group">
            <label htmlFor="margenBrutoMin"><FaPercent /> Margen Bruto Mínimo (%)</label>
            <input
              id="margenBrutoMin"
              name="margenBrutoMin"
              type="number"
              value={busquedaAvanzada.margenBrutoMin}
              onChange={handleChange}
              className={errores.margenBrutoMin ? 'error' : ''}
              placeholder="0"
            />
            {errores.margenBrutoMin && <span className="error-mensaje">{errores.margenBrutoMin}</span>}
          </div>
          <div className="input-group">
            <label htmlFor="rentabilidadSobreVentasMin"><FaChartLine /> Rentabilidad sobre Ventas Mínima (%)</label>
            <input
              id="rentabilidadSobreVentasMin"
              name="rentabilidadSobreVentasMin"
              type="number"
              value={busquedaAvanzada.rentabilidadSobreVentasMin}
              onChange={handleChange}
              className={errores.rentabilidadSobreVentasMin ? 'error' : ''}
              placeholder="0"
            />
            {errores.rentabilidadSobreVentasMin && <span className="error-mensaje">{errores.rentabilidadSobreVentasMin}</span>}
          </div>
        </div>
        <div className="grupo-inputs">
          <div className="input-group">
            <label htmlFor="rentabilidadSobreInversionMin"><FaChartBar /> Rentabilidad sobre Inversión Mínima (%)</label>
            <input
              id="rentabilidadSobreInversionMin"
              name="rentabilidadSobreInversionMin"
              type="number"
              value={busquedaAvanzada.rentabilidadSobreInversionMin}
              onChange={handleChange}
              className={errores.rentabilidadSobreInversionMin ? 'error' : ''}
              placeholder="0"
            />
            {errores.rentabilidadSobreInversionMin && <span className="error-mensaje">{errores.rentabilidadSobreInversionMin}</span>}
          </div>
          <div className="input-group">
            <label htmlFor="roiMin"><FaExchangeAlt /> ROI Mínimo (%)</label>
            <input
              id="roiMin"
              name="roiMin"
              type="number"
              value={busquedaAvanzada.roiMin}
              onChange={handleChange}
              className={errores.roiMin ? 'error' : ''}
              placeholder="0"
            />
            {errores.roiMin && <span className="error-mensaje">{errores.roiMin}</span>}
          </div>
        </div>
        <div className="acciones">
          <button type="button" onClick={aplicarFiltros} className="btn btn-primary">
            <FaSearch /> Aplicar Filtros de Rentabilidad
          </button>
          <button type="button" onClick={() => setVistaActual('lista')} className="btn btn-secondary">
            <FaTimes /> Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};