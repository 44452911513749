import React, { useState, useEffect, useMemo } from 'react';
import { FaEdit, FaTrash, FaArrowLeft, FaTag, FaDollarSign, FaBox, FaChartLine, FaPercent, FaMoneyBillWave, FaBalanceScale, FaChartBar, FaExchangeAlt } from 'react-icons/fa';
import { Line } from 'react-chartjs-2';
import '../styles/DetallesProducto.css';
import { calcularRentabilidad } from '../utils/calculos';

export const DetallesProducto = ({ productoSeleccionado, editarProducto, eliminarProducto, setVistaActual, tarifas }) => {
  const [productoVisualizado, setProductoVisualizado] = useState(productoSeleccionado);
  const [tarifaSeleccionada, setTarifaSeleccionada] = useState(productoSeleccionado.tarifaId || '');

  const tarifasOrdenadas = useMemo(() => {
    return [...tarifas].sort((a, b) => parseFloat(b.porcentaje) - parseFloat(a.porcentaje));
  }, [tarifas]);

  useEffect(() => {
    setProductoVisualizado(productoSeleccionado);
    setTarifaSeleccionada(productoSeleccionado.tarifaId || '');
  }, [productoSeleccionado]);

  const handleTarifaChange = (e) => {
    const nuevaTarifaId = e.target.value;
    setTarifaSeleccionada(nuevaTarifaId);

    let nuevaTarifa;
    if (nuevaTarifaId === "") {
      nuevaTarifa = { id: "tarifa100", nombre: "Sin tarifa", porcentaje: "100" };
    } else {
      nuevaTarifa = tarifas.find(t => t.id === nuevaTarifaId);
    }

    const productoCalculado = calcularRentabilidad({...productoSeleccionado, tarifaId: nuevaTarifaId}, nuevaTarifa);
    setProductoVisualizado(productoCalculado);
  };

  if (!productoVisualizado) return null;

  const {
    id,
    nombre,
    categoria,
    precioVenta,
    costoProducto,
    cantidadVendida,
    gastosGenerales,
    impuestos,
    imagen,
    ingresoTotal,
    costoTotal,
    margenBruto,
    beneficioUnitario,
    beneficioBruto,
    beneficioNeto,
    rentabilidadSobreVentas,
    rentabilidadSobreInversion,
    puntoEquilibrio,
    roi,
  } = productoVisualizado;

  const tarifaAplicada = tarifaSeleccionada ? tarifas.find(t => t.id === tarifaSeleccionada) : { nombre: "Sin tarifa", porcentaje: "100" };

  const precioVentaOriginal = productoSeleccionado.precioVenta;
  const precioVentaCalculado = productoVisualizado.precioVenta;

  const datosGrafico = {
    labels: ['Costo', 'Precio de Venta', 'Beneficio Unitario'],
    datasets: [
      {
        label: 'Valores en $',
        data: [costoProducto, precioVentaCalculado, beneficioUnitario],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const opcionesGrafico = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="detalles-producto">
      <button className="btn-volver" onClick={() => setVistaActual('lista')}>
        <FaArrowLeft /> Volver a la lista
      </button>
      <div className="producto-header">
        <h2>{nombre}</h2>
        <div className="producto-acciones">
          <button className="btn btn-editar" onClick={() => editarProducto(productoSeleccionado)}>
            <FaEdit /> Editar
          </button>
          <button className="btn btn-eliminar" onClick={() => eliminarProducto(id)}>
            <FaTrash /> Eliminar
          </button>
        </div>
      </div>
      <div className="producto-contenido">
        <div className="producto-imagen">
          {imagen ? (
            <img src={imagen} alt={nombre} />
          ) : (
            <div className="imagen-placeholder">Sin imagen</div>
          )}
        </div>
        <div className="producto-info">
          <div className="info-item"><FaTag /> <span>Categoría:</span> {categoria}</div>
          <div className="info-item"><FaDollarSign /> <span>Precio de venta:</span> ${parseFloat(precioVenta).toFixed(2)}</div>
          <div className="info-item"><FaBox /> <span>Costo del producto:</span> ${costoProducto}</div>
          <div className="info-item"><FaMoneyBillWave /> <span>Cantidad vendida:</span> {cantidadVendida}</div>
          <div className="info-item"><FaDollarSign /> <span>Gastos generales:</span> ${gastosGenerales}</div>
          <div className="info-item"><FaPercent /> <span>Impuestos:</span> {impuestos}%</div>
          <div className="info-item">
            <FaPercent /> <span>Tarifa aplicada (simulación):</span>
            <select value={tarifaSeleccionada} onChange={handleTarifaChange}>
              <option value="">Sin tarifa (100%)</option>
              {tarifasOrdenadas.map(tarifa => (
                <option key={tarifa.id} value={tarifa.id}>
                  {tarifa.nombre} ({tarifa.porcentaje}%)
                </option>
              ))}
            </select>
          </div>
          <div className="info-item">
            <FaPercent /> <span>Porcentaje de tarifa actual (simulación):</span> {tarifaAplicada.porcentaje}%
          </div>
          <div className="info-item">
            <FaDollarSign /> <span>Precio de venta original:</span> ${parseFloat(precioVentaOriginal).toFixed(2)}
          </div>
          <div className="info-item">
            <FaDollarSign /> <span>Precio de venta calculado (simulación):</span> ${parseFloat(precioVentaCalculado).toFixed(2)}
          </div>
        </div>
      </div>
      <div className="metricas-financieras">
        <h3>Métricas Financieras</h3>
        <div className="metricas-grid">
          <div className="metrica">
            <FaDollarSign className="metrica-icono" />
            <span className="metrica-titulo">Ingreso Total</span>
            <span className="metrica-valor">${ingresoTotal}</span>
          </div>
          <div className="metrica">
            <FaDollarSign className="metrica-icono" />
            <span className="metrica-titulo">Costo Total</span>
            <span className="metrica-valor">${costoTotal}</span>
          </div>
          <div className="metrica">
            <FaPercent className="metrica-icono" />
            <span className="metrica-titulo">Margen Bruto</span>
            <span className="metrica-valor">{margenBruto}%</span>
          </div>
          <div className="metrica">
            <FaDollarSign className="metrica-icono" />
            <span className="metrica-titulo">Beneficio Unitario</span>
            <span className="metrica-valor">${beneficioUnitario}</span>
          </div>
          <div className="metrica">
            <FaDollarSign className="metrica-icono" />
            <span className="metrica-titulo">Beneficio Bruto</span>
            <span className="metrica-valor">${beneficioBruto}</span>
          </div>
          <div className="metrica">
            <FaDollarSign className="metrica-icono" />
            <span className="metrica-titulo">Beneficio Neto</span>
            <span className="metrica-valor">${beneficioNeto}</span>
          </div>
          <div className="metrica">
            <FaChartLine className="metrica-icono" />
            <span className="metrica-titulo">Rentabilidad sobre Ventas</span>
            <span className="metrica-valor">{rentabilidadSobreVentas}%</span>
          </div>
          <div className="metrica">
            <FaChartBar className="metrica-icono" />
            <span className="metrica-titulo">Rentabilidad sobre Inversión</span>
            <span className="metrica-valor">{rentabilidadSobreInversion}%</span>
          </div>
          <div className="metrica">
            <FaBalanceScale className="metrica-icono" />
            <span className="metrica-titulo">Punto de Equilibrio</span>
            <span className="metrica-valor">{puntoEquilibrio} unidades</span>
          </div>
          <div className="metrica">
            <FaExchangeAlt className="metrica-icono" />
            <span className="metrica-titulo">ROI</span>
            <span className="metrica-valor">{roi}%</span>
          </div>
        </div>
      </div>
      <div className="grafico-rentabilidad">
        <h3>Análisis de Rentabilidad</h3>
        <Line data={datosGrafico} options={opcionesGrafico} />
      </div>
    </div>
  );
};