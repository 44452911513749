import React from 'react';

export const actualizarInventario = (inventario, setInventario, productoId, cantidad, agregarAlHistorial, verificarAlertasInventario) => {
  setInventario(prevInventario => {
    const nuevoInventario = {
      ...prevInventario,
      [productoId]: (prevInventario[productoId] || 0) + cantidad
    };
    agregarAlHistorial('Inventario actualizado', `Producto: ${productoId}, Cantidad: ${cantidad}`);
    verificarAlertasInventario(productoId, nuevoInventario[productoId]);
    return nuevoInventario;
  });
};

export const VistaInventario = ({ inventario, actualizarInventario }) => {
  return (
    <div className="inventario">
      <h2>Gestión de Inventario</h2>
      <ul>
        {Object.entries(inventario).map(([productoId, cantidad]) => (
          <li key={productoId}>
            {productoId}: {cantidad} unidades
            <button onClick={() => actualizarInventario(productoId, 1)}>+</button>
            <button onClick={() => actualizarInventario(productoId, -1)}>-</button>
          </li>
        ))}
      </ul>
    </div>
  );
};