import React from 'react';

export const Historial = ({ historial }) => (
  <div className="historial">
    <h2>Historial de Cambios</h2>
    <ul>
      {historial.map(evento => (
        <li key={evento.id}>
          {new Date(evento.fecha).toLocaleString()}: {evento.accion} - {evento.detalles}
        </li>
      ))}
    </ul>
  </div>
);