export const calcularRentabilidad = (producto, tarifa) => {
  const costo = parseFloat(producto.costoProducto);
  const precioVentaOriginal = parseFloat(producto.precioVenta);
  const cantidad = parseFloat(producto.cantidadVendida);
  const gastos = parseFloat(producto.gastosGenerales) || 0;
  const impuestos = parseFloat(producto.impuestos) || 0;
  
  let precioVenta = precioVentaOriginal;
  
  if (costo > 0 && cantidad > 0 && precioVentaOriginal > 0) {
    if (tarifa && tarifa.porcentaje) {
      const porcentajeTarifa = parseFloat(tarifa.porcentaje) / 100;
      precioVenta = precioVentaOriginal * porcentajeTarifa;
    }
    
    const ingresoTotal = precioVenta * cantidad;
    const costoTotal = costo * cantidad;
    const margenBruto = ((precioVenta - costo) / precioVenta) * 100;
    const beneficioUnitario = precioVenta - costo;
    const beneficioBruto = ingresoTotal - costoTotal;
    const beneficioNeto = beneficioBruto - gastos - (beneficioBruto * impuestos / 100);
    const rentabilidadSobreVentas = (beneficioNeto / ingresoTotal) * 100;
    const rentabilidadSobreInversion = (beneficioNeto / (costoTotal + gastos)) * 100;
    const puntoEquilibrio = gastos / (precioVenta - costo);
    const roi = ((beneficioNeto - (costoTotal + gastos)) / (costoTotal + gastos)) * 100;
    
    return {
      ...producto,
      precioVenta: precioVenta.toFixed(2),
      ingresoTotal: ingresoTotal.toFixed(2),
      costoTotal: costoTotal.toFixed(2),
      margenBruto: margenBruto.toFixed(2),
      beneficioUnitario: beneficioUnitario.toFixed(2),
      beneficioBruto: beneficioBruto.toFixed(2),
      beneficioNeto: beneficioNeto.toFixed(2),
      rentabilidadSobreVentas: rentabilidadSobreVentas.toFixed(2),
      rentabilidadSobreInversion: rentabilidadSobreInversion.toFixed(2),
      puntoEquilibrio: isFinite(puntoEquilibrio) ? Math.ceil(puntoEquilibrio) : 0,
      roi: roi.toFixed(2)
    };
  }
  return producto;
};

export const calcularEstadisticasAvanzadas = (productos, inventario) => {
  const totalProductos = productos.length;
  const rentabilidadPromedio = productos.reduce((acc, p) => acc + parseFloat(p.rentabilidadSobreVentas), 0) / totalProductos;
  const productoMasRentable = productos.reduce((max, p) => parseFloat(p.rentabilidadSobreVentas) > parseFloat(max.rentabilidadSobreVentas) ? p : max, productos[0]);
  const beneficioTotal = productos.reduce((sum, p) => sum + parseFloat(p.beneficioNeto), 0);
  const ventasTotal = productos.reduce((sum, p) => sum + parseFloat(p.ingresoTotal), 0);
  
  const categoriasMasRentables = Object.entries(
    productos.reduce((acc, p) => {
      acc[p.categoria] = (acc[p.categoria] || 0) + parseFloat(p.beneficioNeto);
      return acc;
    }, {})
  ).sort((a, b) => b[1] - a[1]).slice(0, 5);

  const inventarioTotal = Object.values(inventario).reduce((sum, cantidad) => sum + cantidad, 0);
  const productosBajoStock = Object.entries(inventario).filter(([, cantidad]) => cantidad < 5).length;
  
  return { totalProductos, rentabilidadPromedio, productoMasRentable, beneficioTotal, ventasTotal, categoriasMasRentables, inventarioTotal, productosBajoStock };
};