import { db } from '../firebase/config';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';

const tarifasRef = collection(db, 'tarifas');

export const obtenerTarifas = async () => {
  try {
    const snapshot = await getDocs(tarifasRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error al obtener tarifas:", error);
    return [];
  }
};

export const agregarTarifa = async (tarifa) => {
  const docRef = await addDoc(tarifasRef, tarifa);
  return docRef.id;
};

export const actualizarTarifa = async (id, tarifa) => {
  try {
    const docRef = doc(db, 'tarifas', id);
    await updateDoc(docRef, tarifa);
  } catch (error) {
    console.error("Error al actualizar la tarifa:", error);
    throw error;
  }
};

export const eliminarTarifa = async (id) => {
  try {
    const docRef = doc(db, 'tarifas', id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error("Error al eliminar la tarifa:", error);
    throw error;
  }
};